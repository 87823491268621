<template>
    <div class="modalSort">
        <div class="content">
            <span v-html="$translate(title)" />
        </div>
        <div class="hr" />
        <div class="sort-container" v-if="this.options.title === 'SORT'">
            <div
                @click="selectSort(sort, idx)"
                class="sort flex-wrap"
                :class="{ active: idx == sortType }"
                v-html="sort.title"
                :key="idx"
                v-for="(sort, idx) in sortTypes"
            />
        </div>
        <div class="sort-container" v-else>
            <div
                @click="selectSort(sort, idx)"
                class="sort flex-wrap"
                :class="{ active: idx == sortGender }"
                v-html="sort.title"
                :key="idx"
                v-for="(sort, idx) in sortGenders"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalSort',
    props: ['options'],
    computed: {
        title() {
            return (this.options || {}).title
        },
        sortType() {
            return (this.options || {}).sortType
        },
        sortTypes() {
            return (this.options || {}).sortTypes
        },
        sortGender() {
            return (this.options || {}).sortGender
        },
        sortGenders() {
            return (this.options || {}).sortGenders
        },
    },
    methods: {
        selectSort(sort, idx) {
            if (sort.orderby === 'close_office') {
                const me = this.$store.getters.me
                if (me.profile.office_state) {
                    this.$emit('close', idx)
                } else {
                    this.$modal
                        .custom({
                            component: 'ModalRegionStation',
                            options: {
                                model: 'state',
                                icon: 'chevron_left',
                            },
                        })
                        .then(() => {
                            this.$store.dispatch('loadMe')
                            this.$emit('close', idx)
                        })
                }
            } else {
                this.$emit('close', idx)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.modalSort {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    // height: 208px;
    border-radius: 12px 12px 0 0;
    padding: 0;

    .content {
        padding: 24px 24px 16px 24px;
        color: black;
        font-size: 18px;

        @include f-medium;
    }

    .hr {
        width: 100%;
        height: 1px;
        background: $grey-02;
        margin-top: 8px;
        margin-bottom: 12px;
    }

    .sort-container {
        padding: 0px 24px 40px 24px;
        .sort {
            margin-top: 28px;
            font-size: 16px;
            color: black;
            @include f-regular;

            &.active {
                color: $purple-primary;
                @include f-medium;
            }
        }
    }
}
</style>
